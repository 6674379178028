import React from 'react';

const Footer = () => (
  <div className="flex flex-row justify-center m-auto w-screen h-12 text-gray-100 space-x-6">
    <a href="https://www.buymeacoffee.com/alihaghani" target="_blank" rel="noreferrer">Grab me a 🍺</a>
    <a href="https://twitter.com/playlistify_app" target="_blank" rel="noreferrer">Twitter</a>
    <a href="mailto:ali@playlistify.app" target="_blank" rel="noreferrer">Contact</a>
  </div>
);

export default Footer;
