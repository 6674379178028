import React, { useEffect, useState } from 'react';
import querystring from 'querystring';

import {
  API_URL,
  DEEZER_APP_ID,
  DEEZER_PERMS,
  DEEZER_REDIRECT_URI,
  SPOTIFY_CLIENT_ID,
  SPOTIFY_REDIRECT_URI,
  SPOTIFY_SCOPES
} from '../config';
import { getParamsObject } from '../utils/url';
import { isValidTracklistUrl } from '../util';
import MusicKit from '../actions/MusicKit';
import platforms from '../constants/platforms';
import PlaylistifyLogo from '../assets/playlistify.svg';
import { showNotification } from '../utils/notification';

import CreatePlaylistButton from './CreatePlaylistButton';
import PlatformSelection from './PlatformSelection';
import ReactTooltip from 'react-tooltip';
import TracklistInput from './TracklistInput';

let musicKit;
let musicKitInstance;

const Home = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [token, setToken] = useState(null);
  const [tracklistInput, setTracklistInput] = useState('');

  useEffect(() => {
    // Deezer redirect handler
    const windowParams = getParamsObject(window.location.search);
    if (windowParams.code) {
      setSelectedPlatform(platforms.DEEZER.systemValue);
      setToken(windowParams.code);
      showNotification(`Logged in with ${platforms.DEEZER.displayValue}.`);
    }

    // Spotify redirect handler
    if (window.location.hash) {
      const token = window.location.hash.substr(1).split('&')[0].split("=")[1]

      if (token && window.opener) {
        window.opener.spotifyCallback(token)
      }
    }
  }, []);

  const tracklistInputChangeHandler = event => {
    setTracklistInput(event.target.value);
  };

  const authPlatform = platform => {
    if (platform === platforms.SPOTIFY.systemValue) {
      const spotifyPopup = window.open('https://accounts.spotify.com/authorize?' +
        querystring.stringify({
          response_type: 'token',
          client_id: SPOTIFY_CLIENT_ID,
          scope: SPOTIFY_SCOPES,
          redirect_uri: SPOTIFY_REDIRECT_URI,
          state: querystring.stringify({ tracklist: tracklistInput, platform })
        }),
        'Login with Spotify',
        'width=800,height=600'
      );

      window.spotifyCallback = (userToken) => {
        spotifyPopup.close();
        setToken(userToken);
        setSelectedPlatform(platform);
        showNotification('Logged in with Spotify.');
      }
    }

    if (platform === platforms.APPLE_MUSIC.systemValue) {
      musicKit = MusicKit.sharedProvider();
      musicKit.configure();
      musicKitInstance = musicKit.getMusicInstance();

      musicKitInstance.authorize()
        .then((userToken) => {
          setToken(userToken);
          setSelectedPlatform(platform);
          showNotification('Logged in with Apple Music.');
        })
    }

    if (platform === platforms.DEEZER.systemValue) {
      window.location = 'https://connect.deezer.com/oauth/auth.php?' +
        querystring.stringify({
          app_id: DEEZER_APP_ID,
          redirect_uri: DEEZER_REDIRECT_URI,
          perms: DEEZER_PERMS
        });
    }
  };

  const createPlaylist = platform => {
    if (platform === platforms.SPOTIFY.systemValue) {
      fetch(`${API_URL}/playlist`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify({ url: tracklistInput, token, platform })
      })
        .then(res => res.json())
        .then(data => {
          setIsProcessing(false);

          if (data.error) {
            return showNotification(`Error: ${data.error}`);
          }
          const { playlistId, foundTracks, totalTracks, userId } = data;
          showNotification(`Spotify playlist created with ${foundTracks}/${totalTracks} tracks matched.`);
        })
        .catch(err => {
          showNotification('Error connecting to server.')
          setIsProcessing(false);
        })
    }

    if (platform === platforms.APPLE_MUSIC.systemValue) {
      fetch(`${API_URL}/playlist`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify({ url: tracklistInput, token: musicKit.getDeveloperToken(), userToken: token, platform })
      })
        .then(res => res.json())
        .then(async data => {
          setIsProcessing(false);

          if (data.error) {
            return showNotification(`Error: ${data.error}`);
          }
          const { playlistId, playlistRegion, foundTracks, totalTracks } = data;
          showNotification(`Apple Music playlist created with ${foundTracks}/${totalTracks} tracks matched.`);
        })
        .catch(err => {
          showNotification('Error connecting to server.');
          setIsProcessing(false);
        })
    }

    if (platform === platforms.DEEZER.systemValue) {
      fetch(`${API_URL}/playlist`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify({ url: tracklistInput, token, platform })
      })
        .then(res => res.json())
        .then(data => {
          setIsProcessing(false);

          if (data.error) {
            return showNotification(`Error: ${data.error}`);
          }
          const { playlistId, foundTracks, totalTracks } = data;
          showNotification(`Deezer playlist created with ${foundTracks}/${totalTracks} tracks matched.`)
        })
        .catch(err => {
          showNotification('Error connecting to server.');
          setIsProcessing(false);
        })
    }
  };

  const createPlaylistClickHandler = event => {
    event.preventDefault();
    if (!selectedPlatform) return showNotification('Choose a platform to create playlist.');
    if (!isValidTracklistUrl(tracklistInput)) return showNotification('Unsupported tracklist URL.');
    setIsProcessing(true);

    token ? createPlaylist(selectedPlatform) : authPlatform(selectedPlatform);
  };

  const selectPlatformHandler = async event => {
    setToken(null);
    authPlatform(event.target.value);
  };

  return (
    <div
      className="flex flex-1 items-center w-screen h-full">
      <div
        className="flex justify-center m-auto w-screen max-w-4xl h-full md:w-3/4 md:h-1/2 bg-gradient-to-r md:from-white md:via-gray-50 md:to-white border-none md:border md:rounded-md border-gray-50 md:shadow">
        <div className="block m-auto w-11/12 max-w-2xl">
          <div className="flex mb-8 justify-center items-center select-none">
            <img className="w-3/4" src={PlaylistifyLogo} alt="Playlistify"/>
          </div>

          <PlatformSelection selectedPlatform={selectedPlatform} selectPlatformHandler={selectPlatformHandler} />
          <TracklistInput tracklistInput={tracklistInput} tracklistInputChangeHandler={tracklistInputChangeHandler} />
          <CreatePlaylistButton createPlaylistClickHandler={createPlaylistClickHandler} isProcessing={isProcessing} />
        </div>
      </div>
      <ReactTooltip className="max-w-full" effect="solid" delayShow={100} place="right" />
    </div>
  );
};

export default Home;
