import React from 'react';

import platforms from '../constants/platforms';

import PlatformSelectionItem from './PlatformSelectionItem';

const PlatformSelection = ({ selectedPlatform, selectPlatformHandler }) => (
  <>
    <span
      className="text-white md:text-gray-700"
      data-tip="Select the platform you want to create a playlist on. You'll need to authenticate with the selected platform."
    >
      Create a playlist on...
    </span>
    <div className="flex flex-col space-y-1 mt-4 md:space-y-0 md:flex-row md:space-x-6 md:justify-center">
      {
        Object.values(platforms).map(platform => (
          <PlatformSelectionItem
            displayValue={platform.displayValue}
            icon={platform.icon}
            key={platform.systemValue}
            selectPlatformHandler={selectPlatformHandler}
            selectedPlatform={selectedPlatform}
            systemValue={platform.systemValue}
          />
        ))
      }
    </div>
  </>
);

export default PlatformSelection;
