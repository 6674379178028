import React from 'react'
import Checkmark from './Checkmark';

const PlatformSelectionItem = ({
  displayValue,
  icon,
  selectPlatformHandler,
  selectedPlatform,
  systemValue,
}) => {
  const isSelected = selectedPlatform === systemValue;
  return (
    <div
      className={`inline-flex items-center w-auto h-auto bg-gradient-to-r from-gray-50 to-white shadow-md rounded-lg`}
    >
      <label className="inline-flex cursor-pointer items-center space-x-2 my-2 mx-2">
        <input
          checked={isSelected}
          className="hidden"
          onChange={selectPlatformHandler}
          type="radio"
          value={systemValue}
        />
        <Checkmark checked={isSelected} />
        <img
          alt={displayValue}
          className="w-8 rounded-md"
          src={icon}
        />
        <span className="text-gray-600">{displayValue}</span>
      </label>
    </div>
  );
};

export default PlatformSelectionItem;
