import jwt from 'jsonwebtoken'

import { APPLE_KEY_ID, APPLE_TEAM_ID, APPLE_MUSIC_PRIVATE_KEY } from '../config';

export default class MusicKit {
  static sharedProvider() {
    if(!MusicKit.instance) {
      MusicKit.instance = new MusicKit();
    }
    return MusicKit.instance;
  }

  getMusicInstance() {
    return window.MusicKit.getInstance();
  }

  getDeveloperToken() {
    return jwt.sign({}, APPLE_MUSIC_PRIVATE_KEY, {
      algorithm: 'ES256',
      expiresIn: '180d',
      issuer: APPLE_TEAM_ID,
      header: {
        alg: 'ES256',
        kid: APPLE_KEY_ID
      }
    });
  }

  configure() {
    window.MusicKit.configure({
      developerToken: this.getDeveloperToken(),
      app: {
        name: 'Playlistify',
        build: '2019.8.1',
        icon: 'https://i.imgur.com/DR30Vlb.png'
      }
    });
  }
}
