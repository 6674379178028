import React from 'react';

import Spinner from './Spinner';

const CreatePlaylistButton = ({ createPlaylistClickHandler, isProcessing }) => (
  <div className="flex mt-8 justify-center">
    <button
      className="inline-flex bg-transparent md:bg-black text-white focus:outline-none hover:bg-white hover:text-black border-2 border-white md:border-transparent hover:border-black font-semibold px-6 py-3 rounded-md shadow-md"
      onClick={createPlaylistClickHandler}
      type="button"
    >
      {isProcessing
        ? <><Spinner />Creating playlist</>
        : 'Create playlist'
      }
    </button>
  </div>
);

export default CreatePlaylistButton;
