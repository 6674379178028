import AppleMusicIcon from '../assets/applemusic.png'
import DeezerIcon from '../assets/deezer.png'
import SpotifyIcon from '../assets/spotify.png'

export default {
  SPOTIFY: {
    displayValue: 'Spotify',
    systemValue:'SPOTIFY',
    icon: SpotifyIcon,
  },
  APPLE_MUSIC: {
    displayValue: 'Apple Music',
    systemValue: 'APPLE_MUSIC',
    icon: AppleMusicIcon,
  },
  DEEZER: {
    displayValue: 'Deezer',
    systemValue: 'DEEZER',
    icon: DeezerIcon,
  },
};
