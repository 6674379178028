import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Notifications from 'react-notify-toast';

import Footer from './components/Footer';
import Home from './components/Home';

class App extends Component {

  render = () => {
    const content = () => {
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Redirect from='*' to='/'/>
          </Switch>
        </BrowserRouter>
      )
    }

    return (
      <div className="flex flex-col h-full bg-skygaze-purple">
        <Notifications />
        <main className="flex-1">
          {content()}
        </main>
        <Footer />
      </div>
    )
  }
}
export default App;
