export const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.playlistify.app'
  : 'http://localhost:3000';
export const APP_URL = process.env.NODE_ENV === 'production'
  ? 'https://playlistify.app'
  : 'http://localhost:3001';
export const SPOTIFY_CLIENT_ID = '7f0b46027ff34358a2802499a66facf4';
export const SPOTIFY_SCOPES = 'user-read-email playlist-modify-public playlist-modify-private';
export const SPOTIFY_REDIRECT_URI = APP_URL;
export const APPLE_TEAM_ID = '6S5A8224F6';
export const APPLE_KEY_ID = '4VN7HBZ4MJ';
export const APPLE_MUSIC_PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----\n' +
  'MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgev+OjuTDSOMmk3vw\n' +
  'VXi7GN7AWkKMXJ3qlryknCu4SImgCgYIKoZIzj0DAQehRANCAARVpdxPQI9GkTAO\n' +
  'zzHSRAtqxm+3vNZ3sPXlsZyr505JnxmfPua20zZlv7tKaKHf9nyZxpXF7LZhPygi\n' +
  'PuoRHZa2\n' +
  '-----END PRIVATE KEY-----';

export const DEEZER_APP_ID = '452582';
export const DEEZER_REDIRECT_URI = APP_URL
export const DEEZER_PERMS = 'basic_access,email,manage_library';
