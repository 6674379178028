import React from 'react';

const TracklistInput = ({ tracklistInput, tracklistInputChangeHandler }) => (
  <div className="mt-4">
    <span
      className="text-white md:text-gray-700"
      data-tip="Enter the URL for a tracklist from 1001tracklists.com, setlist.fm, livetracklist.com, or mixesdb.com."
    >
        Using songs from...
    </span>
      <input
        className="flex-1 appearance-none border border-transparent w-full py-2 px-4 bg-white text-gray-800 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-peach focus:border-transparent mt-2"
        onChange={tracklistInputChangeHandler}
        placeholder="Tracklist URL"
        value={tracklistInput}
      />
  </div>
);

export default TracklistInput;
